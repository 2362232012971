<template>
  <v-container>
    <h3>{{ $t("message.title-approved-detail") }}</h3>
    <v-card>
      <v-col class="pr-2 pb-2" cols="12">
        <form>
          <v-row>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <v-label class="label">{{ $t("message.label-id") }}</v-label>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <span class="">{{ resultDetail.id }}</span>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <v-label class="label">{{ $t("message.label-merchant") }}</v-label>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <span class="">{{ resultDetail.merchant_name }}</span>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <v-label class="label">{{ $t("message.label-company") }}</v-label>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <span class="">{{ resultDetail.company_name }}</span>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <v-label class="label">{{ $t("message.label-pic") }}</v-label>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <span class="">{{ resultDetail.pic_name }}</span>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <v-label class="label">{{ $t("message.label-email") }}</v-label>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <span class="">{{ resultDetail.email }}</span>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <v-label class="label">{{ $t("message.label-phone") }}</v-label>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <span class="">{{ resultDetail.phone }}</span>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <v-label class="label">{{ $t("message.label-address") }}</v-label>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <span class="">{{ resultDetail.address }}</span>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <v-label class="label">{{ $t("message.label-fee") }}</v-label>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <span class="">{{ resultDetail.fee }}</span>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <v-label class="label">{{ $t("message.label-status") }}</v-label>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <span class="">{{ is_active }}</span>
            </v-col>
          </v-row>
        </form>
      </v-col>
    </v-card>
    <div class="fixed-div" style="margin-top: 20px">
      <v-row class="d-flex justify-space-between mb-6">
        <v-col>
          <v-btn
            rounded
            color="secondary"
            dark
            style="float:start"
            @click="$router.go(-1)"
          >
            {{ $t("message.btn-back") }}
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            rounded
            color="primary"
            dark
            style="float:right"
            @click.prevent="updateMerchant(resultDetail.id)"
          >
            {{ $t("message.btn-update") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <!-- </div> -->
  </v-container>
</template>

<script>
export default {
  name: "DetailApproved",
  methods: {
    dispatchApproveList() {
      let id = this.$route.params.id;
      return this.$store.dispatch("approve_module/fetchDetailApprove", id);
    },
    updateMerchant(paramsId) {
      this.$router.push(`/updateMerchant/${paramsId}/update`);
    },
  },
  computed: {
    is_active() {
      let cekIsActive = this.$store.state.approve_module.resultDetail.is_active
      if(cekIsActive == 1) {
        return this.$t("message.label-active")
      } else {
        return this.$t("message.label-inactive")
      }
    },
    resultDetail() {
      return this.$store.state.approve_module.resultDetail;
    },
  },
  created() {
    this.dispatchApproveList();
  },
};
</script>

<style></style>
